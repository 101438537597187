import { useApolloClient, useLazyQuery } from '@apollo/client';
import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { useBatchedQuery, useUnbatchedQuery } from 'bank-common-client';
import { addDays, isValid, parse } from 'date-fns';
import { PlainButton, fonts, space } from 'folio-common-components';
import { isArrayOfAtLeastOne, notEmpty, partition } from 'folio-common-utils';
import promiseSettledAggregate from 'promise-settled-aggregate';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaLayout } from 'use-media';
import { FirstPaintDocument } from '../../common-queries.generated';
import { ListStatusMessage } from '../../components/list-status-message';
import { NumberBadge } from '../../components/number-badge';
import { LedgerCategoriesDocument } from '../../components/shared/queries.generated';
import {
  tabActiveStyle,
  tabBarStyle,
  tabStyle,
} from '../../components/tab-nav';
import { useEventFilters } from '../../event-filter-context';
import { useChangedEvents } from '../../hooks/use-changed-events';
import {
  LoadingTransaction,
  LoadingTransactionList,
  TransactionList,
} from './TransactionList';
import {
  GetNewEventsAndBalanceDocument,
  NewTransactionPageDataChangeThisNameDocument,
  TransactionPageDataDocument,
} from './queries.generated';
import { eventsSorter } from './sorter';

type Props = {
  searchTerm: string;
};

function beforeDateFromDateParameter(dateString: string) {
  // TODO: do we need some time offset handling here?
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  if (!isValid(date)) {
    return null;
  }

  // The date from the URL should be inclusive, so add one day here
  return addDays(date, 1).toISOString();
}

export const SEARCH_TERM_MIN_LENGTH = 3;

const NonMemoizedNewTransactionsView: React.FC<Props> = ({ searchTerm }) => {
  // Prefetch categories
  useBatchedQuery(LedgerCategoriesDocument);

  const { eventFilters } = useEventFilters();

  const [params] = useSearchParams();
  const urlFromDate = params.get('fraDato');
  const beforeDate = urlFromDate
    ? beforeDateFromDateParameter(urlFromDate) ?? undefined
    : undefined;

  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const { error, data, fetchMore } = useUnbatchedQuery(
    NewTransactionPageDataChangeThisNameDocument,
    {
      variables: {
        incompleteOnly: eventFilters.status === 'incomplete',
        searchTerm,
        before: beforeDate,
        includeIncompleteCount: true,
      },
      // Using the default policy here ('cache-first') leads to a bug where the
      // data does not correspond the search term.  To reproduce:
      // 1. Quickly write "fi" in the search input
      // 2. Remove "i", wait ~200 ms and remove "f"
      // The search field now has the value "", but the rendered data
      // corresponds to a search for "f". This might be fixed in the latest
      // Apollo client version, but we currently use an older version.
      fetchPolicy: 'cache-and-network',
      // pollInterval: ten minutes,
      // skipPollAttempt: () => document.hidden, // Apollo client 3.9
    },
  );

  const client = useApolloClient();
  const [getEvents] = useLazyQuery(GetNewEventsAndBalanceDocument, {
    fetchPolicy: 'network-only',
  });
  const handleChangedEvents = React.useCallback(
    async (eventFids: readonly string[]) => {
      Sentry.addBreadcrumb({
        message: 'Got changed events',
        data: { eventFids },
      });
      try {
        const apolloCache = client.cache.extract();
        const [eventsInCache, eventsNotInCache] = partition(eventFids, fid => {
          const stringifiedKey = JSON.stringify({ fid });
          return (
            Object.hasOwn(apolloCache, `Event:${stringifiedKey}`) ||
            Object.hasOwn(apolloCache, `SalaryPayment:${stringifiedKey}`)
          );
        });

        const promises = [
          isArrayOfAtLeastOne(eventsInCache)
            ? getEvents({ variables: { eventFids: eventsInCache } })
            : null,
          isArrayOfAtLeastOne(eventsNotInCache)
            ? client.refetchQueries({ include: [TransactionPageDataDocument] })
            : // if no new events, refetch first paint to get new balances
              client.refetchQueries({ include: [FirstPaintDocument] }),
        ].filter(notEmpty);

        await promiseSettledAggregate(promises);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [client, getEvents],
  );

  useChangedEvents(handleChangedEvents);

  const bottomEleRef = React.useRef<HTMLDivElement>(null);
  const earliestTimestamp = data?.events.earliestTimestampInPage;

  React.useEffect(() => {
    const ele = bottomEleRef.current;
    if (!ele || !earliestTimestamp) {
      return;
    }

    const options = {
      rootMargin: '500px',
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(async entry => {
        if (!entry.isIntersecting) {
          return;
        }

        setIsLoadingMore(true);
        try {
          await fetchMore({
            variables: {
              before: earliestTimestamp,
              includeIncompleteCount: false,
            },
          });
        } catch {
          // FIXME: handle this somehow
        }
        setIsLoadingMore(false);
      });
    }, options);

    observer.observe(ele);

    return () => observer.disconnect();
  }, [fetchMore, earliestTimestamp]);

  const combinedEvents = data?.events.combinedItems;
  const events = React.useMemo(() => {
    if (!combinedEvents) {
      return [];
    }

    return Array.from(combinedEvents).sort(eventsSorter);
  }, [combinedEvents]);

  return (
    <>
      <div
        css={css`
          /* Create a stacking context */
          position: relative;
          z-index: 0;
          ${space([16], 'margin-top')};

          /*
          Make sure the page is high enough, to avoid the page jumping when there are no search results.
          Also make sure the intersection-observed element is outside of the viewport to avoid triggering
          extra queries.
          TODO: look into the exact calculation for this.
          */
          min-height: calc(100vh + 500px);
        `}
      >
        <TransactionFilter
          incompleteCount={data?.events.incompleteCount ?? undefined}
        />

        {error && !data ? (
          <ListStatusMessage>
            Klarte ikke å hente bevegelsene. Vennligst prøv igjen!
          </ListStatusMessage>
        ) : !data ? (
          // Cache incompleteCount and use as count here
          <LoadingTransactionList count={40} />
        ) : data.events.combinedItems.length === 0 ? (
          <div
            css={css`
              text-align: center;
            `}
          >
            <div css={fonts.font500bold}>Finnes ikke</div>
            <p>Ingen bevegelser passer med søket ditt.</p>
          </div>
        ) : (
          <>
            <MemoizedTransactionList
              events={events}
              incompleteCount={data?.events.incompleteCount ?? undefined}
            />

            {/*
            Show skeleton transactions as a loading indicator while fetching
            more transactions. As long as there are more transactions to load,
            we set off space for these skeleton transactions to avoid unnecessary
            jumps in the scrollbar.
            */}
            {data.events.earliestTimestampInPage
              ? Array.from({ length: 4 }).map((_, index, array) => (
                  <div
                    key={index}
                    css={css`
                      visibility: ${isLoadingMore ? 'visible' : 'hidden'};
                      opacity: ${1 - index / array.length};
                    `}
                  >
                    <LoadingTransaction index={index} />
                  </div>
                ))
              : null}
          </>
        )}
      </div>
      <div ref={bottomEleRef}></div>
    </>
  );
};

export const NewTransactionsView = React.memo(NonMemoizedNewTransactionsView);
const MemoizedTransactionList = React.memo(TransactionList);

interface TransactionFilterProps {
  incompleteCount: number | undefined;
}

export const TransactionFilter: React.FC<TransactionFilterProps> = ({
  incompleteCount,
}) => {
  // Keep a cached value so that we can show something while
  // another search is in progress
  const [cachedIncompleteCount, setCachedIncompleteCount] = React.useState(
    incompleteCount ?? 0,
  );

  React.useLayoutEffect(() => {
    if (incompleteCount != null) {
      setCachedIncompleteCount(incompleteCount);
    }
  }, [incompleteCount]);

  const isWide = useMediaLayout('(min-width: 420px)');
  const { eventFilters, setEventFilters } = useEventFilters();

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 8px;
        flex-wrap: wrap;
        ${tabBarStyle};
        ${space([32], 'margin-bottom')};
      `}
    >
      <div>
        <PlainButton
          css={[tabStyle, eventFilters.status === 'all' && tabActiveStyle]}
          onClick={() => setEventFilters({ status: 'all' })}
          aria-pressed={eventFilters.status === 'all'}
          aria-label="Siste bevegelser"
        >
          {isWide ? 'Siste bevegelser' : 'Siste'}
        </PlainButton>
        <PlainButton
          css={[
            tabStyle,
            eventFilters.status === 'incomplete' && tabActiveStyle,
          ]}
          onClick={() => setEventFilters({ status: 'incomplete' })}
          aria-pressed={eventFilters.status === 'incomplete'}
        >
          Mangler noe
          {cachedIncompleteCount > 0 && (
            <NumberBadge
              aria-label={`(${cachedIncompleteCount})`}
              color="black"
              css={css`
                margin-left: 8px;
              `}
            >
              {cachedIncompleteCount < 100 ? cachedIncompleteCount : '99+'}
            </NumberBadge>
          )}
        </PlainButton>
      </div>
    </div>
  );
};
